import React from "react";
import moment from "moment";
import GetCurrency from "../../../helpers/services";
import ReadMore from "../../../components/ReadMoreText";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useMediaQuery } from "react-responsive";
import DOMPurify from "dompurify";
import { CurrencyWithAmount, addAttributesToLinks } from "../../../helpers/utils";

const TicketCard = ({
	hasGroupDiscount,
	discountContent,
	data,
	ticketStartTime,
	todaysTime,
	showVenue,
	ticketStatus,
	ticketPrice,
	buttonType,
	getTZ,
	eventData,
	fontFamily,
	moreBtnColor,
	nameFontWeight,
	groupDiscounts,
	matchedEventProperties
}) => {
	const styles = {
		fontFamily: fontFamily ? fontFamily : "Prompt",
		fontWeight: 400,
		fontSize: "16px",
		margin: "0px",
		color: matchedEventProperties?.match ? matchedEventProperties?.properties?.textColor : "#474747",
	};

	const handleMediaQueryChange = (matches) => {
		smallerScreen = matches;
		// matches will be true or false based on the value for the media query
	};
	let smallerScreen = useMediaQuery(
		{ maxWidth: 535 },
		undefined,
		handleMediaQueryChange,
	);

	function replaceAll(string, search, replace) {
		return string.split(search).join(replace);
	}



	const showAdditionalVenue =
		eventData.additional_venue_details_rich_text ===
			data.additional_venue_details_rich_text
			? false
			: true;

	const findMaxDiscount = (ticket, groupDiscount) => {
		const { ticket_id, totalSelected, ticket_price } = ticket;

		let maxDiscount = 0;
		let maxDiscountType = 0; // 1 for flat, 2 for percentage
		let discountVal = 0;

		groupDiscount.forEach(discount => {
			const {
				attached_ticket_ids,
				minimum_tickets,
				maximum_tickets,
				coupon_type,
				discount: discountValue
			} = discount;

			// Check if the ticket ID is attached to the discount and if the totalSelected is within the min and max ticket range
			if (
				attached_ticket_ids.includes(ticket_id) && minimum_tickets === 0
			) {
				let calculatedDiscount = 0;

				// Calculate the discount based on coupon type
				if (coupon_type === 2) {
					calculatedDiscount = ticket_price - ((discountValue / 100) * ticket_price);
				} else if (coupon_type === 1) {
					calculatedDiscount = discountValue;
				}

				discountVal = discountValue
				// Check if the calculated discount is greater than the current maxDiscount
				if (calculatedDiscount > maxDiscount) {
					maxDiscount = calculatedDiscount;
					maxDiscountType = coupon_type;
				}
			}
		});

		// Calculate the total discount and final price
		let totalDiscount = 0;

		if (maxDiscountType === 2) {
			totalDiscount = (ticket_price - maxDiscount);
		} else if (maxDiscountType === 1) {
			totalDiscount = maxDiscount;
		}

		return { totalDiscount, maxDiscountType, discountVal };
	};

	const localTimezone = moment.tz.guess();


	const isDiscountValid = hasGroupDiscount &&
		!data.sold_out &&
		moment().valueOf() < moment.utc(`${data.end_timestamp}`).local().valueOf();





	return (
		<>

			<div className="ticket-card mx-0 mx-sm-3 mt-0 mb-0 mb-sm-4 mt-sm-3 mt-3 p-3 p-sm-3 py-4 p-3 position-relative">

				{/* For Discount tag */}
				{isDiscountValid && (
					<div className="d-flex justify-content-end off-img position-absolute">
						<Tippy content={<div>{discountContent}</div>}>
							<img src="/img/off-img.svg" alt="off" className="pointer" />
						</Tippy>
					</div>
				)}
				{/* Ticket */}
				<div className="d-flex flex-column pt-2  pb-sm-2  pb-0 ">
					<div className="mb-2 d-flex title-section flex-column">
						<div className="name-section mb-2">
							<p className="ticket-name">{data.ticket_name}</p>
						</div>
						{data.description && (
							<div className="desc-section mb-sm-2 mb-0">
								<p className="ticket-desc" style={{ color: '#474747' }}>
									{/* <ReadMore
                                    content={data.description}
                                    maxChar={260}
                                    styles={{
                                        fontFamily: "Prompt",
                                        fontWeight: 400,
                                        fontSize: "13px",
                                        margin: "0px"
                                    }}
                                    color={moreBtnColor ? moreBtnColor : ""}
                            
                                /> */}
									{data.description && (
										<div
											style={styles}
											onClick={(e) => e.stopPropagation()}
											dangerouslySetInnerHTML={{
												__html: addAttributesToLinks(
													String(data?.description || "")
														.replaceAll(
															"font-family: Hind",
															`font-family: ${fontFamily ? fontFamily : "Prompt"}`
														)
														.replaceAll("<li", '<li class="text-start"')
														.replaceAll("<p", '<p class="mb-1"') || ""
												),
											}}

										/>
									)}
								</p>
							</div>
						)}
						{data.venue && data.latitude && data.longitude && showVenue ? (
							<a
								href={`https://www.google.com/maps/search/?api=1&query=${data.venue.replace(
									/ /g,
									"+",
								)}`}
								target="_blank"
								className="text-start d-flex mb-1"
								style={{
									fontSize: "13px",
									fontFamily: "Prompt",
									textDecoration: "none",
								}}
							>
								<i
									className="fa-solid fa-location-dot mt-1 me-1"
									style={{ fontSize: "14px", cursor: "pointer" }}
								></i>
								{data.venue}
							</a>
						) : data.venue && showVenue ? (
							<p
								className="mt-2 text-start d-flex mt-1 mb-2"
								style={{ fontSize: "13px", fontFamily: "Prompt" }}
							>
								<i
									className="fa-solid fa-location-dot mt-1 me-1"
									style={{ fontSize: "14px", cursor: "pointer" }}
								></i>
								{data.venue}
							</p>
						) : (
							""
						)}
						{data.additional_venue_details_rich_text && showAdditionalVenue && (
							<div
								className="mt-0 text-start mb-sm-2 mb-0"
								style={{
									fontSize: "13px",
									fontFamily: "Prompt",
								}}
								dangerouslySetInnerHTML={{
									__html: addAttributesToLinks(DOMPurify.sanitize(
										data.additional_venue_details_rich_text
											?.replaceAll(
												'style="font-family: Hind, sans-serif; margin: 0px;"',
												styles,
											)
											.replaceAll(
												'style="font-family: Hind, sans-serif;"',
												styles,
											)
											.replaceAll(
												'style="margin: 0px; font-family: Hind, sans-serif;"',
												styles,
											),
									)),
								}}
							/>
						)}
					</div>
				</div>
				<div className="availibility py-1 px-2 mb-0 mb-sm-2 d-inline-block" style={{ color: matchedEventProperties?.match ? matchedEventProperties?.properties?.textColor : '#474747' }}>
					<p className="my-auto">
						{ticketStartTime > todaysTime ? `Starts On: ` : `Available Till: `}
						{ticketStartTime > todaysTime
							? `${moment
								.utc(`${data.start_timestamp}`)
								.local()
								.format("Do MMM YYYY, hh:mm A")} ${getTZ(data.start_timestamp, localTimezone).includes("+")
									? `GMT${getTZ(data.start_timestamp, localTimezone)}`
									: `${getTZ(data.start_timestamp, localTimezone)}`
							}`
							: `${moment
								.utc(`${data.end_timestamp}`)
								.local()
								.format("Do MMM YYYY, hh:mm A")} ${getTZ(data.end_timestamp, localTimezone).includes("+")
									? `GMT${getTZ(data.end_timestamp, localTimezone)}`
									: `${getTZ(data.end_timestamp, localTimezone)}`
							} `}
					</p>
				</div>
				{ticketStatus && (
					<span className="remaining-count">{ticketStatus}</span>
				)}

				<div className="mt-2 d-flex align-items-center justify-content-between flex-wrap">
					{data.ticket_type === 3 ? (
						<p className="dontaion-info text-center me-2 d-flex flex-wrap">
							<span>
								Min&nbsp;
								<div className="d-inline-block ticket-price">
									{data.minimum_price && (
										<CurrencyWithAmount
											currencyName={data.currency_name}
											number={data.minimum_price}
										/>
									)}
								</div>
								{data.maximum_price && <>&nbsp;-&nbsp;</>}
							</span>
							{data.maximum_price && (
								<>
									<span>
										Max&nbsp;
										<div className="d-inline-block ticket-price">
											<CurrencyWithAmount
												currencyName={data.currency_name}
												number={data.maximum_price}
											/>
										</div>
									</span>
								</>
							)}
						</p>
					) : (
						<p className="ticket-price me-2 py-2">
							{data.currency_name && data.ticket_price !== 0 ? (
								<>
									{ticketPrice}
								</>
							) : (
								"FREE"
							)}
						</p>
					)}
					<div className="d-inline-block ms-auto py-2">{buttonType}</div>
				</div>
			</div>
			<style jsx>{`
            .off-img{
                position: absolute;
                right: -4px;
                top: 0;
            }
            .ticket-name{
                font-family: ${fontFamily ? fontFamily : "Prompt"};
                font-size: 18px;
                font-weight: ${nameFontWeight ? nameFontWeight : "600"};
				word-break: break-word;
            }

            .remaining-count{
                letter-spacing: 0.5px;
                font-family: ${fontFamily ? fontFamily : "Prompt"};
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #DC3545;
                display: flex;
                }
            .timings p{
                font-family: ${fontFamily ? fontFamily : "Nunito"};
                font-style: normal;
                font-weight: 600;
                font-size: 11px;
                line-height: 16px;
                letter-spacing: 0px;
                /* identical to box height */
                color: #101010;
            }

            .availibility{
                background: #F0F0F0;
                border-radius: 5px;
				color: #474747;
				color: ${matchedEventProperties?.match ? matchedEventProperties?.properties?.textColor : "#474747"} 

            }
            .availibility p{
                font-family: ${fontFamily ? fontFamily : "Prompt"};
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
				//color: #474747;
				color: ${matchedEventProperties?.match ? matchedEventProperties?.properties?.textColor : "#474747"} 

            }

            .ticket-price{
                font-family: ${fontFamily ? fontFamily : "Prompt"};
                font-size: ${smallerScreen ? "20px" : "26px"};
                font-weight: 600;
                letter-spacing: 0em;
            }
            .ticket-card{
                background: #FFFFFF;
                border-radius: 5px;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
            }

            .ticket-desc{
				word-break: break-word;
                font-family: "Prompt";
                font-weight: 400;
                font-size: 14px;
                margin: 0px;
	
            }
			@media only screen and (max-width: 576px) {

				.ticket-name{
					font-size:16px;
					font-weight:500;
				}
				.availibility{
					margin: auto;
					display: flex !important;
					width: 100%;
					text-align: center;
					justify-content: center;
					border-radius: 2px;
				}
				.availibility p{
					font-size: 14px;
					font-weight: 400;
				}
				.ticket-price {
					font-size: 24px;
					font-weight: 500;
				}
				.register-btn{
					font-size:14px;
					font-weight:700;
					box-shadow: 0 0 0 !important;
					height: 42px !important;
				}
        
				.sold-out-btn{
					font-size: 14px;
				}
				.sold-out-btn span{
                    font-size: 14px !important;
                }
				.register-btn{
					box-shadow: 0 0 0;
				}
			}
			@media only screen and (max-width: 355px) {
				.availibility p{
					font-size: 13px;
					
				}

			}
			

			@media (max-width: 575px) {
				.minimalistic-btn {
					width: auto;
					padding: 7px 14px;
					font-size: 14px;
					border-radius: 2px !important;
				}
				.ticket-price{
				   font-size: 24px;
				   font-family: "Prompt";
				   font-weight: 500;
				}
				.ticket-card {
					padding:16px 16x !important;
				}
			}
			@media (max-width: 376px) {
				.availibility p{
					font-size : 13px !important;
					letter-spacing:0px;
				}
				
			}

            `}</style>
		</>
	);
};

export default TicketCard;